<template>
  <div class="flex flex-col justify-between">
    <List
      resource="appPermissions"
      :basePath="basePath"
      :fields="fields"
      :request-params="{appId: appId, nonDeleted: true}"
      ref="list"
      track-by="id"
      on-row-click="none"
      infinity-scroll
      :search="true"
      search-placeholder="Search for a permission by name or description"
    />
  </div>
</template>

<script>
  import List from '@/components/auth/list/List';
  import ModalNavigation from '@/mixins/ModalNavigation';

  export default {
    name: 'PermissionsList',
    components: {
      List,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      appId: function () {
        return this.$route.params.appId;
      }
    },
    data() {
      return {
        fields: [
          {
            name: 'alias',
            sortField: 'alias',
            title: 'permission name',
          },
          {
            name: 'description',
            title: 'permission description',
          }
        ],
      };
    },
    mounted() {
      this.$authDataProvider.getOne('apps', {id: this.appId})
        .then(app => {
          this.setActiveModalTitle(`${app.name} application permissions`);
        })
        .catch(error => this.notifyError(error.message));
    }
  }
</script>
